import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import hawlogo from "../images/gaesteinfologo.svg";


function IndexPage() {  
 


  return (
    <div className="flex-col flex text-center min-h-screen align-middle items-center content-center justify-center">
          <AniLink to="gastinfo">
      <img
          alt="Logo"
          className="w-48 h-48 "
          src={hawlogo}
        />   
        
      </AniLink>
      <h1>GÄSTEINFORMATION</h1>     
       </div>
    );
}

export default IndexPage;
